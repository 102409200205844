import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { firebaseConfig } from './configs/fbConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'; // make sure you add this for firestore
import "./assets/css/nucleo-icons.css";
import "./assets/css/main.css";
import "./assets/css/util.css";
import "./assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);