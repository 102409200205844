
import firebase from 'firebase/app';
import 'firebase/firestore'; // make sure you add this for firestore
import { action, makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import app from "../../app";
import {
  getTypedObjectFromFirebase,
  _getCollectionReference,
} from "./fireStoreHelpers/fireStoreHelpers";
import "firebase/firestore";
import "firebase/auth";
import { User, UserFactory } from "../../models";

export interface IFirebaseStore {
  firebaseAppDefined: boolean;
  uid: string;
  db?: firebase.firestore.Firestore;
  fbFunctions?: firebase.functions.Functions;
}

export class FirebaseStore implements IFirebaseStore {
  firebaseAppDefined = false;
  uid = '';
  db?: firebase.firestore.Firestore;
  fbFunctions?: firebase.functions.Functions;
  id = uuidv4();

  constructor() {
    makeAutoObservable(this);
  }

  @action initializeFB = () => {
    setInterval(() => {
      if (!this.firebaseAppDefined) {
        if (firebase.app()) {
          // Your code here
          this.db = firebase.firestore();
          this.fbFunctions = firebase.functions();
          this.startAuthListener();
          this.firebaseAppDefined = true;
        }
      }
    }, 100);
  };

  @action initializeUser = async (uid: string) => {
    const address = app.user.address;
    // console.log("initializeUser ", uid);
    // console.log("address ", address);
    try {
      if (address != uid) {
        app.user.setIsAuthenticated(false);
        this.processLogoutRequest();
      } else {
        this.uid = uid;
        //  console.log("Is Logged in initializeUser ");
        const userData = await getTypedObjectFromFirebase<User>("users", uid);
        app.user.setIsAuthenticated(true);
        app.user.setUser(userData);
      }
    } catch (err) {
      console.log("LOGIN_ERROR", err.message);
    }
  };

  //TODO
  //Discuss the option of when signed messages are needed vs simply connecting the wallet.
  //Figure out why we are getting so many auth popup signature requests
  //Re-Route Page refresh to the home page to avoid some errors
  startAuthListener = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      //  debugger;
      if (user) {
        //    console.log("Is Logged in startAuthListener");
        //    console.log("uid ", user.uid);
        app.user.setIsAuthenticated(true);
        await this.initializeUser(user.uid);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        // ...
      } else {
        //;
        //   console.log("startAuthListener Fired");
        //   console.log("Not Logged In");
        app.user.setIsAuthenticated(false);
        // User is signed out
        // ...
      }
    });
  };

  @action updateTokenToNotForSale = async (tokenUidToUpdate: string) => {
    const tokenUpdateNotForSale = firebase
      .functions()
      .httpsCallable("updateTokenToNotForSale");
    const result = await tokenUpdateNotForSale({
      tokenUid: tokenUidToUpdate,
    });
  };

  @action updateTokenForSale = async (
    tokenUidToUpdate: string,
    price: string
  ) => {
    const tokenUpdateForSale = firebase
      .functions()
      .httpsCallable("updateTokenToForSale");
    const result = await tokenUpdateForSale({
      tokenUid: tokenUidToUpdate,
      askingPrice: price,
    });
  };

  @action processLoginRequest = async () => {
    const address = await app.web3.setAccounts();
    const messageToSign = "Songbird Authentication";
    const messageSignature = await app.web3.signPersonalMessage(
      address,
      messageToSign
    );
    try {
      const getAuthToken = firebase
        .functions()
        .httpsCallable("validateSignatureGetToken");
      const result = await getAuthToken({
        address: address,
        signature: messageSignature,
      });
      const token = result.data.token;
      // Read result of the Cloud Function.
      const loginResult = await this.db?.app
        .auth()
        .signInWithCustomToken(token);
      const uid = loginResult?.user?.uid;
      const isNewUser = loginResult?.additionalUserInfo?.isNewUser;
      if (isNewUser && uid) {
        this.registerNewUser(uid.toLowerCase());
      }
      console.log("uid ", uid);
      return uid ? uid : "";
    } catch (err) {
      console.error(err);
      return "Error";
    }
  };

  @action registerNewUser = async (uid: string) => {
    console.log("GH", uid);
    try {
      const userDocument = this.db?.collection("users").doc(uid);
      userDocument?.set({
        address: uid,
        userName: uid,
        avatar: "",
        registered: Date.now(),
      });
    } catch (err) {
      console.log("LOGOUT_ERROR", err.message);
    }
  };

  @action processLogoutRequest = async () => {
    try {
      await firebase.auth().signOut();
      app.user.setUser(UserFactory.fromVoid());
      window.location.href = "/";
    } catch (err) {
      console.log("LOGOUT_ERROR", err.message);
    }
  };

  @action saveToDB = async <T>(
    collectionPath: string,
    documentUID: string,
    dataToSave: T
  ): Promise<void> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.doc(documentUID).set({ ...dataToSave });
  };


  @action deleteDBDocument = async <T>(
    collectionPath: string,
    documentUID: string
  ): Promise<void> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.doc(documentUID).delete();
  };

  @action getAllDocsInCollection = async <T>(
    collectionPath: string
  ): Promise<firebase.firestore.DocumentData> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.get();
  };

  @action getNewFirestoreDocID = async (
    collectionPath: string
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return firestoreCollection.doc();
  };

}
