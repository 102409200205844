import { lazy } from "react";

const HomePage = lazy(() => import("../../pages/home/HomePage"));
const ComingSoon = lazy(() => import("../../pages/coming-soon/ComingSoonPage"));
//const AboutPage = lazy(() => import("../../pages/about/AboutPage"));
//const AttributePointsPage = lazy(() => import("../../pages/attribute-points/AttributePointsPage"));
//const CommunityPage = lazy(() => import("../../pages/community/CommunityPage"));
//const FAQPage = lazy(() => import("../../pages/faq/FAQPage"));
//const NFTAssetsPage = lazy(() => import("../../pages/nft-assets/NFTAssetsPage"));
//const StrategyPage = lazy(() => import("../../pages/strategy/StrategyPage"));
//const RoadMapPage = lazy(() => import("../../pages/roadMap/RoadMapPage"));
//const MembershipPage = lazy(() => import("../../pages/membership/MembershipPage"));
//const KingsDialectPage = lazy(() => import("../../pages/kings-dialect/KingsDialectPage"));
//const KCoinPage = lazy(() => import("../../pages/k-coin/KCoinPage"));

export const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    secret: false,
    component: ComingSoon,
    menu: false,
    icon: "",
    tooltip: "Go Home",
  },
  {
    path: "/coming-soon",
    name: "Coming Soon",
    exact: true,
    secret: false,
    component: ComingSoon,
    menu: false,
    icon: "",
    tooltip: "ComingSoon",
  },
  /*  {
     path: "/about",
     name: "About",
     exact: true,
     secret: false,
     component: AboutPage,
     menu: false,
     icon: "",
     tooltip: "Learn More",
   },
   {
     path: "/attribute-points",
     name: "Attribute Points",
     exact: true,
     secret: false,
     component: AttributePointsPage,
     menu: false,
     icon: "",
     tooltip: "Learn how attribute points work",
   },
   {
     path: "/community",
     name: "Community",
     exact: true,
     secret: false,
     component: CommunityPage,
     menu: false,
     icon: "",
     tooltip: "Connect with others",
   },
   {
     path: "/faq",
     name: "FAQ",
     exact: true,
     secret: false,
     component: FAQPage,
     menu: false,
     icon: "",
     tooltip: "Frequently Asked Questions",
   },
   {
     path: "/k-coin",
     name: "K-Coin",
     exact: true,
     secret: false,
     component: KCoinPage,
     menu: false,
     icon: "",
     tooltip: "Learn more about K-Coin",
   },
   {
     path: "/kings-dialect",
     name: "Kings Dialect",
     exact: true,
     secret: false,
     component: KingsDialectPage,
     menu: false,
     icon: "",
     tooltip: "Learn the special language that people speak around here.",
   },
   {
     path: "/membership",
     name: "Membership",
     exact: true,
     secret: false,
     component: MembershipPage,
     menu: false,
     icon: "",
     tooltip: "Learn how to get more from the network.",
   },
   {
     path: "/nft-assets",
     name: "NFT Assets",
     exact: true,
     secret: false,
     component: NFTAssetsPage,
     menu: false,
     icon: "",
     tooltip: "Check out the available digital assets",
   },
   {
     path: "/road-map",
     name: "Road Map",
     exact: true,
     secret: false,
     component: RoadMapPage,
     menu: false,
     icon: "",
     tooltip: "See our road map for the future",
   },
   {
     path: "/strategy",
     name: "Strategy",
     exact: true,
     secret: false,
     component: StrategyPage,
     menu: false,
     icon: "",
     tooltip: "Get strategy tips",
   }, */
];
export const dropDownRoutes = [
  {
    path: "/nft-assets",
    name: "My NFTs",
    exact: true,
    secret: false,
    component: HomePage,
    menu: true,
    icon: "",
  },
  {
    path: "/profile",
    name: "Edit Profile",
    exact: true,
    secret: false,
    component: HomePage,
    menu: true,
    icon: "iconName",
  },
];
