import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";

export interface IVarsStore {
  baseURI: string;
}

export class VarsStore implements IVarsStore {
  id = uuidv4();
  baseURI = 'https://ipfs.io/ipfs/';

  constructor() {
    makeAutoObservable(this);
  }

  @action initializeVars(): void {
    console.log('Initialized ');
  }

  @computed getImageUrlFromIPFSHash(ipfsHash: string): string {
    return this.baseURI + ipfsHash;
  }

}