

import { VarsStore } from "../@state/vars/varsStore";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { UserStore } from "../@state/user/userStore";
import { FirebaseStore } from "../@state/firebase/firebaseStore";
import { ModalStore } from "../@state/modal/modalStore";
import { Web3Store } from "../@state/web3/web3Store";

class AppState {
  id = uuidv4();
  vars: VarsStore;
  web3: Web3Store;
  user: UserStore;
  fb: FirebaseStore;
  modal: ModalStore;

  constructor() {
    makeAutoObservable(this);
    this.vars = new VarsStore();
    this.web3 = new Web3Store();
    this.fb = new FirebaseStore();
    this.user = new UserStore();
    this.modal = new ModalStore();
  }
}

const app = new AppState();
export default app;
