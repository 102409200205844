import firebase from 'firebase/app';
import 'firebase/firestore'; // make sure you add this for firestore

export const _getCollectionReference = (collectionPath: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
  return firebase.firestore().collection(collectionPath);
};



// Pass it a collectionPath string as the path to the collection in firestore & a type argument representing the 'type' (schema) of the docs in the collection
export const _typedCollections = <T>(collectionPath: string): firebase.firestore.CollectionReference<T> => firebase.firestore().collection(collectionPath).withConverter(_converter<T>());

// This helper function pipes your types through a firestore converter
export const _converter = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as T
});


const getTypedCollectionReference = <T>(collectionPath: string): firebase.firestore.CollectionReference<T> => {
  const firstoreCollection: firebase.firestore.CollectionReference<T> = _typedCollections<T>(collectionPath);
  return firstoreCollection;
};

export const getTypedObjectFromFirebase = async <T>(collectionPath: string, uid: string): Promise<T> => {
  const firstoreDoc: firebase.firestore.CollectionReference<T> = getTypedCollectionReference<T>(collectionPath);
  const objectToReturn = await firstoreDoc.doc(uid).get();
  return objectToReturn.data() as T;
};