// ** Router Import
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import app from ".";
import { Observer, observer } from 'mobx-react';
import { routes } from "../utils";
import "../assets/scss/index.scss";

const App = observer((): JSX.Element => {

  const setWindowListeners = () => {
    if (window.ethereum !== undefined) {
      app.web3.initializeWeb3();
      window.ethereum.autoRefreshOnNetworkChange = false;
      window.ethereum.on('chainChanged', app.web3.setChainId);
      window.ethereum.on('accountsChanged', app.web3.setAccounts);
    }
  }
  //Initialize Web3 Window Listeners
  window.addEventListener('DOMContentLoaded', setWindowListeners);

  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    document.body.classList.toggle("light-mode");
    // Specify how to clean up after this effect:
    //Initialize Default Variables and Firebase
    app.vars.initializeVars();
    app.fb.initializeFB();

    return function cleanup() {
      document.body.classList.toggle("index-page");
      document.body.classList.toggle("light-mode");
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Suspense
          fallback={
            <div className="main-body">
              <div>Loading... </div>
            </div>
          }
        >
          {routes.map((route, i) => {
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
        </Suspense>
      </Switch>
    </Router>
  );
});

export default App;
